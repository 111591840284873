import axios from 'axios';

class BaseApi {
  constructor(resource) {
    if (this.constructor.name === 'BaseApi') {
      throw new Error(`${this.constructor.name}: can not create instance of abstract class`);
    }

    this.resource = resource;
    if (this.resource === undefined) {
      throw new TypeError("Must pass 'resource' property");
    }
  }

  static freezeItem(route) {
    return axios.delete(route, {
      params: {
        soft: true,
      },
    });
  }
}

export default BaseApi;
