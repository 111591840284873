import {
  SET_ORDERS,
  EDIT_ORDER,
  ADD_ORDER,
  MARK_ORDER_FOR_EXPORT,
  SET_ORDERS_LOADING,
  SET_VALIDATION_ORDER_ERRORS,
  SET_ORDER_MODAL_VISIBILITY,
  SET_ORDER_MODAL_DATA,
  RESET_ORDERS_STATE,
  // eslint-disable-next-line import/named
  SET_CLICK,
  SET_GLOBAL_DATES,
  RESET_CLICKED
} from 'src/store/actionTypes';

import { initialBaseTableState, getBaseTableState } from '../helpers/baseTableState';

import { getArrayWithUpdatedObject } from '../../utils/helpers';
import {
  getCurrentOrderData,
  getMarkedExportOrder,
} from '../../components/management/general/helpers';

const initialState = {
  ...initialBaseTableState,
};

const getCurrentOrdersData = orders => orders.map(order => getCurrentOrderData(order));

const rootReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GLOBAL_DATES:
      return { ...state,
        globalDates: payload
      };
    case RESET_ORDERS_STATE:
      return { ...initialState };

    case RESET_CLICKED: 
    return {
      ...state,
      total: null
    }
    case SET_ORDERS:
      return {
        ...state,
        ...payload,
        data: getCurrentOrdersData(payload.orders.data),
        countTypes: { ...payload.countTypes },
        isClicked: !!(!payload.orders.lastPage || Object.keys(payload.countTypes).length),
      };
    case MARK_ORDER_FOR_EXPORT:
      return {
        ...state,
        data: getArrayWithUpdatedObject(getMarkedExportOrder(payload), state.orders.data),
      };

    case EDIT_ORDER:
      return getBaseTableState(state, { type, payload: getCurrentOrderData(payload) });

    case ADD_ORDER:
    case SET_ORDERS_LOADING:
    case SET_VALIDATION_ORDER_ERRORS:
    case SET_ORDER_MODAL_DATA:
    case SET_ORDER_MODAL_VISIBILITY:
      return getBaseTableState(state, { type, payload });
    case SET_CLICK:
      return { ...state, isClicked: true };
    default:
      return state;
  }
};

export default rootReducer;
