import axios from 'axios';
import { addNotification } from '../../utils/notifications';
import AuthModel from '../../api/AuthModel';
import AppendsModel from '../../api/AppendsModel';
import { AUTH_SUCCESS, AUTH_LOGOUT, SET_AUTH_ROLES, SET_USER_LOADING } from '../actionTypes';

const setApiAuthorizationToken = token => {
  axios.defaults.headers.common.Authorization = `bearer ${token}`;
};
const removeApiAuthorizationToken = () => {
  axios.defaults.headers.common.Authorization = null;
};

export const Logout = () => {
  localStorage.removeItem('token');
  removeApiAuthorizationToken();
  return {
    type: AUTH_LOGOUT,
  };
};

export const AuthSuccess = user => {
  return {
    type: AUTH_SUCCESS,
    user,
  };
};

export const setUserLoading = status => {
  return {
    type: SET_USER_LOADING,
    status,
  };
};

export const SetRoles = backRoles => {
  const roles =
    backRoles &&
    backRoles.reduce((updatedRoles, role) => {
      // eslint-disable-next-line no-param-reassign
      updatedRoles[role.slug] = role;
      return updatedRoles;
    }, {});
  return {
    type: SET_AUTH_ROLES,
    roles,
  };
};

export const login = authData => {
  return async dispatch => {
    const {
      data: { user, token },
    } = await AuthModel.login(authData);
    setApiAuthorizationToken(token.token);
    localStorage.setItem('token', token.token);
    dispatch(AuthSuccess(user));
    return user;
  };
};

export const activeUser = authData =>{
  return async _ => {
    const {data: { user } } = await AuthModel.activeUser(authData);
    addNotification({ message: 'Credentials Accepted' });
    return user;
  };
}

export const changeUserPassword = authData =>{
  return async _ => {
    const {data: { user } } = await AuthModel.changeUserPassword(authData);
    addNotification({ message: 'Password Changed Successfully' });
    return user;
  };
}

export const authCheckProfile = () => {
  return async (dispatch, getState) => {
    if (!getState().auth.userLoading) {
      dispatch(setUserLoading(true));
      const { data: roles } = await AppendsModel.getRoles();
      dispatch(SetRoles(roles));

      const token = localStorage.getItem('token');
      if (token) {
        setApiAuthorizationToken(token);
        const { data: user } = await AuthModel.getProfile();
        dispatch(AuthSuccess(user));
      } else {
        dispatch(AuthSuccess(null));
      }
      dispatch(setUserLoading(false));
    }
  };
};
