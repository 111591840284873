import React, { useEffect, useState } from 'react';
import './UiInput.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import hashIcon from 'src/assets/images/hash.svg';
import eyeIcon from 'src/assets/images/eye.svg';

const UiInput = React.forwardRef((props, ref) => {
  let {
    icon: Icon,
    type,
    onKeyDown,
    disabled,
    readOnly,
    placeholder,
    error,
    hash,
    passwordRevealer,
    togglePasswordVisiblity,
    onChange,
    background,
    value,
    inputStyles,
    ...inputProps
  } = props;

  function changeValue(e) {
    if (type === 'mask' || type === 'datepicker') return onChange(e);
    const { value: eventValue } = e.target;
    if (type === 'number') return onChange(eventValue === '' ? null : Number(eventValue));
    return onChange(eventValue);
  }

  const inputValue = value === null ? '' : value;

  return (
    <div
      className={classNames('ui-input', {
        'ui-input--error': error,
        'ui-input--has-hash': hash,
      })}
    >
      <input
        type={type}
        ref={ref}
        onClick={props.onClick}
        name={props.name}
        placeholder={placeholder}
        value={inputValue}
        defaultValue={props.defaultValue}
        disabled={disabled}
        readOnly={readOnly}
        onChange={changeValue}
        onKeyDown={onKeyDown}
        style={{ background, ...inputStyles }}
        {...inputProps}
      />
      <div className="ui-input__icon">{Icon}</div>
      <div className="ui-input__hash">{hash && <img src={hashIcon} alt="hash" />}</div>
      <div onClick={togglePasswordVisiblity} className="ui-input__eye">{passwordRevealer && <img width={24} height={24} src={eyeIcon} alt="passwordRevealer" />}</div>
    </div>
  );
});

UiInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'number', 'tel', 'datepicker', 'mask']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  hash: PropTypes.bool,
  background: PropTypes.string,
  inputStyles: PropTypes.object,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),

  // events
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};

UiInput.defaultProps = {
  hash: false,
};

export default UiInput;
