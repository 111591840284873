import React, { createContext } from 'react';
import { connect } from 'react-redux';

export const UserContext = createContext({ user: null, userRole: null, roles: null });

const UserContextProvider = ({ user, roles, children }) => {
  const userRole = user && user.role_id;
  const adminRoles = [roles.admin?.id, roles.super_admin?.id];
  const isAdminSystemUser = adminRoles.includes(userRole);
  return (
    <UserContext.Provider value={{ user, userRole, roles, isAdminSystemUser }}>
      {children}
    </UserContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    roles: state.auth.roles,
  };
};

export default connect(mapStateToProps)(UserContextProvider);
