import * as yup from 'yup';

export const email = () => yup.string().email();
export const password = () =>
  yup
    .string()
    .min(8)
    .max(255)
    .matches(
      /(?=.*\d)(?=.*[a-z|а-я])(?=.*[A-Z|А-Я])/,
      'Password should contain at least 1 digit and 1 uppercase letter and 1 lowercase letter'
    );
