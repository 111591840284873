import React, { useState } from 'react';
import styles from './MainLayout.module.scss';
import Header from '../../UI/header/Header';
import MainNavigationMenu from '../../UI/navigationMenu/MainNavigationMenu';

const MainLayout = ({ children }) => {
  const [isMobileMenuActive, toggleMobileMenu] = useState(false);

  return (
    <>
      <MainNavigationMenu
        isMobileMenuActive={isMobileMenuActive}
        onBurgerClick={() => toggleMobileMenu(false)}
      />

      <main className={styles.content}>
        <Header onBurgerClick={() => toggleMobileMenu(true)} />
        {children}
      </main>
    </>
  );
};

export default MainLayout;
