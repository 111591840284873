import React, { useContext, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './navigationMenu.module.scss';

// icons
import Place from '../icons/navIcons/Place';
import Person from '../icons/navIcons/Person';
import CubeIcon from '../icons/navIcons/CubeIcon';
import LogoutIcon from '../icons/navIcons/LogoutIcon/LogoutIcon';
import DispatchingIcon from '../icons/navIcons/DispatchIcon';
import TrainIcon from '../icons/navIcons/TrainIcon';
import BurgerMenuIcon from '../icons/BurgerMenuIcon/BurgerMenuIcon';

import MenuSingleLink from './MenuSingleLink';
import { Logout } from '../../../store/auth/actions';
import { UserContext } from '../../../context/UserContext';
import { getPrivateRoutes } from '../../../routes';
import useWindowWidth from '../../hooks/useWindowWidth';
import { breakpoints } from '../../../utils/constant';

function getLinkPathAndRoles(path) {
  const matchedRoute = getPrivateRoutes().find(route => route.path === path);
  return {
    linkPath: path,
    roles: matchedRoute.roles,
  };
}

const MainNavigationMenu = ({ isMobileMenuActive, onBurgerClick }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userRole } = useContext(UserContext);
  const windowWidth = useWindowWidth();

  const allMenuItems = useMemo(
    () => [
      { ...getLinkPathAndRoles('/order-management'), IconComponent: Person },
      { ...getLinkPathAndRoles('/order-dispatching'), IconComponent: DispatchingIcon },
      { ...getLinkPathAndRoles('/train-management'), IconComponent: TrainIcon },
      { ...getLinkPathAndRoles('/shipment-tracking'), IconComponent: Place },
      { ...getLinkPathAndRoles('/main-management'), IconComponent: CubeIcon },
    ],
    [],
  );

  const roleMenuItems = useMemo(
    () => allMenuItems.filter(item => item.roles.map(role => role?.id).includes(userRole)),
    [allMenuItems, userRole],
  );

  const callLogout = () => {
    dispatch(Logout());
    history.push('/login');
  };

  const closeMenuItem = (
    <li className={styles.navigationItem}>
      <BurgerMenuIcon isActive onClick={onBurgerClick} />
    </li>
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapper__active]: isMobileMenuActive,
      })}
    >
      <ul className={styles.navigationWrap}>
        {windowWidth < breakpoints.sm && closeMenuItem}
        {roleMenuItems &&
          roleMenuItems.map(({ linkPath, IconComponent }) => (
            <li key={linkPath} className={styles.navigationItem}>
              <MenuSingleLink
                currentPath={location.pathname}
                linkPath={linkPath}
                IconComponent={IconComponent}
              />
            </li>
          ))}

        <li className={styles.navigationItem} style={{ marginTop: 'auto' }}>
          <LogoutIcon onClick={callLogout} />
        </li>
      </ul>
    </div>
  );
};

export default MainNavigationMenu;
