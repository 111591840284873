import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, roles, currentUser, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!currentUser || currentUser.deleted_at) {
        // user not logged in - redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }
      // check if route is restricted by role
      if (roles && !roles.map(role => role.id).includes(currentUser.role_id)) {
        return <Redirect to={{ pathname: '/' }} />;
      }

      // authorised so return our private component
      return <Component {...props} />;
    }}
  />
);

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
