import {
  SET_TRAINS,
  ADD_TRAIN,
  EDIT_TRAIN,
  SET_TRAINS_LOADING,
  SET_TRAIN_MODAL_VISIBILITY,
  SET_VALIDATION_TRAIN_ERRORS,
  SET_TRAIN_MODAL_DATA,
  RESET_TRAINS_STATE,
  EDIT_TRAIN_ORDER,
} from 'src/store/actionTypes';

import { initialBaseTableState, getBaseTableState } from '../helpers/baseTableState';
import { getArrayWithUpdatedObject } from '../../utils/helpers';
import { getCurrentTrainData } from '../../components/management/general/helpers';

const initialState = {
  ...initialBaseTableState,
};

const getTrainsWithUpdatedOrder = (trainsList, trainId, updatedOrder) => {
  const trainIndex = trainsList.findIndex(currentTrain => currentTrain.id === trainId);
  const updatedOrders = getArrayWithUpdatedObject(updatedOrder, trainsList[trainIndex].orders);

  const updatedTrains = [...trainsList];
  updatedTrains[trainIndex].orders = updatedOrders;
  updatedTrains[trainIndex] = { ...updatedTrains[trainIndex] };
  return updatedTrains;
};

const getCurrentTrainsData = trains => trains.map(train => getCurrentTrainData(train));

const trainsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TRAINS:
      return { ...state, ...payload, data: getCurrentTrainsData(payload.trains.data), countTypes: { ...payload.countTypes }, };

    case EDIT_TRAIN_ORDER:
      return {
        ...state,
        data: getTrainsWithUpdatedOrder(state.data, payload.trainId, payload.order),
      };

    case RESET_TRAINS_STATE:
      return { ...initialState };

    case EDIT_TRAIN:
      return getBaseTableState(state, { type, payload: getCurrentTrainData(payload) });

    case ADD_TRAIN:
    case SET_TRAINS_LOADING:
    case SET_TRAIN_MODAL_VISIBILITY:
    case SET_VALIDATION_TRAIN_ERRORS:
    case SET_TRAIN_MODAL_DATA:
      return getBaseTableState(state, { type, payload });
    default:
      return state;
  }
};

export default trainsReducer;
