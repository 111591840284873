import moment from 'moment';

export const objectFromArray = array => {
  return array.reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});
};

export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
};

export const getSelectOptionObjectFromStrings = arrayOfStrings =>
  arrayOfStrings.map(str => ({ label: str, value: str }));

export const capitalizeFirstLetter = str => str[0].toUpperCase() + str.slice(1);

export const getFilledProperties = params => {
  const filteredParams = {};
  for (const key of Reflect.ownKeys(params)) {
    if (params[key] !== null && params[key] !== '') {
      filteredParams[key] = params[key];
    }
  }
  return filteredParams;
};

export const includesObjectInArray = (arr, obj) => {
  return Boolean(arr.find(el => el.id === obj.id));
};

export const getArrayWithUpdatedObject = (updatedObject, arr) => {
  const newArr = [...arr];
  const indexUpdatedObject = newArr.findIndex(item => updatedObject.id === item.id);
  newArr[indexUpdatedObject] = { ...updatedObject };
  return newArr;
};

export const downloadBlobDocument = (response, blobType, linkDownload) => {
  const blob = new Blob([response.data], {
    type: blobType,
  });
  const link = document.createElement('a');
  link.download = linkDownload;
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href);
};

export const getSortedWagonsByPosition = wagons => {
  return [...wagons].sort((a, b) => {
    const { position: aPos } = a;
    const { position: bPos } = b;
    if ((aPos && aPos > bPos && bPos) || (!aPos && bPos)) {
      return 1;
    }
    if ((aPos && aPos < bPos && bPos) || (aPos && !bPos)) {
      return -1;
    }
    return 0;
  });
};

export const getReadableDate = date => date && moment(date).format('YYYY-MM-DD HH:mm:ss');
export const getReadableDateWithFormat = date => date && moment(date).format('DD-MM-YYYY');
export const getReadableNumber = number =>
  typeof number === 'number' && window.Intl.NumberFormat('ru-RU').format(number);
export const getReadableWeight = weight => `${getReadableNumber(weight)} kg`;
