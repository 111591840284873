import {
  EDIT_WAGON,
  RESET_WAGONS_STATE,
  SET_VALIDATION_WAGON_ERRORS,
  SET_WAGON_MODAL_DATA,
  SET_WAGON_MODAL_VISIBILITY,
  SET_WAGONS,
  SET_WAGONS_LOADING,
} from 'src/store/actionTypes';

import { initialBaseTableState, getBaseTableState } from '../helpers/baseTableState';

const initialState = {
  ...initialBaseTableState,
};

const wagonsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_WAGONS_STATE:
      return { ...initialState };
    case SET_WAGONS:
      return { ...state, ...payload };
    case SET_WAGONS_LOADING:
    case SET_WAGON_MODAL_VISIBILITY:
    case SET_WAGON_MODAL_DATA:
    case SET_VALIDATION_WAGON_ERRORS:
    case EDIT_WAGON:
      return getBaseTableState(state, { type, payload });
    default:
      return state;
  }
};

export default wagonsReducer;
