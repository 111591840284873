import axios from 'axios';
import store from './store';
import { authCheckProfile, Logout } from './store/auth/actions';

const getAPIURL = () => {
  const dict = {
    development: process.env.REACT_APP_API_URL,
    test: process.env.TEST_API,
    integration: process.env.TEST_API,
    production: process.env.API_PROD,
  };
  return dict[process.env.NODE_ENV] || dict.development;
};

axios.defaults.baseURL = `${getAPIURL()}/v1/`;

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const authHeader = config.headers.common.Authorization;
  const axiosToken = authHeader && authHeader.length && authHeader.split(' ')[1];
  if (axiosToken !== token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = `bearer ${token}`;
    store.dispatch(authCheckProfile());
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.data.message === 'InvalidJwtToken') {
      store.dispatch(Logout);
    }
    return Promise.reject(error);
  },
);
