import React from 'react';
import UiTableHeaderButton from '../../UI/UiTable/UiTableHeaderButton/UiTableHeaderButton';
import { getOrderStatusValue } from '../../../utils/constant';

export const getOrderTrainNumber = order => order?.train?.number;
export const getOrderFirstLocomotive = order =>
  order.train?.locomotives && order.train?.locomotives[0];

export function getExportHeader(orders, onExportHeaderClickFunc) {
  const isOrderForExporting = orders.find(order => order.isOrderExports);
  const handleExportCLick = () => isOrderForExporting && onExportHeaderClickFunc();

  return (
    <UiTableHeaderButton
      isButton={isOrderForExporting}
      onClick={handleExportCLick}
      label="Export"
    />
  );
}

export function getMarkedExportOrder(clickedOrder) {
  // isOrderExports - it custom property that helps to mark order as "includes" to export
  return { ...clickedOrder, isOrderExports: !clickedOrder.isOrderExports };
}

export function getExportsOrders(orders) {
  return orders.filter(order => order.isOrderExports).map(order => order.id);
}

export const getCurrentOrderData = order => {
  const modifiedOrder = order.departed_by && {
    ...order,
    train: order.departed_by.train,
    wagons: order.departed_by.wagons,
    trainData: order.train
  };


  return order.status === getOrderStatusValue('Finished') ? modifiedOrder : order;
};

export const getCurrentTrainData = train => {
  train.orders &&
    train.orders.forEach((order, index) => {
      train.orders[index] = getCurrentOrderData(order);
    });

  const modifiedTrain = train.departed_with && {
    ...train,
    locomotives: train.departed_with.locomotives,
    wagons: train.departed_with.wagons,
  };

  return train.status === getOrderStatusValue('Finished') ? modifiedTrain : train;
};

export const getUserFullName = user => user && `${user.first_name} ${user.last_name}`;
