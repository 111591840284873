import axios from 'axios';
import BaseApi from './BaseApi';

class AuthModel extends BaseApi {
  constructor() {
    super('');
  }

  getProfile() {
    return axios.get(`${this.resource}/profile`);
  }

  login(data) {
    return axios.post(`${this.resource}/login`, data);
  }

  activeUser(data) {
    return axios.post(`${this.resource}/user/active`, data);
  }

  changeUserPassword(data) {
    return axios.post(`${this.resource}/user/change-password`, data);
  }

  resetPassword(data) {
    return axios.post(`${this.resource}/password/reset`, data);
  }
}

export default new AuthModel();
