import React, { useContext, useState } from 'react';
// components
import BaseBtn from 'src/components/UI/buttons/BaseBtn/BaseBtn';
import UiInputContainer from 'src/components/UI/inputs/UiInputContainer/UiInputContainer';
// validation
import { useForm } from 'react-hook-form';
import { modifyServerErrors } from 'src/utils/validation/validationHelpers';
// redux
import { useDispatch } from 'react-redux';
import { activeUser, changeUserPassword } from 'src/store/auth/actions';

import { useHistory } from 'react-router-dom';
import loginValidationSchema from './loginValidationSchema';
import passwordConfirmSchema from './passwordConfirmSchema';
import UiInput from '../../components/UI/inputs/UiInput/UiInput';
import UiInputPassword from '../../components/UI/inputs/UiInput/UiInputPassword';

import styles from './UserActivation.module.scss';
import './UserActivation.scss';
import ArrowForwardIcon from '../../components/UI/icons/ArrowForwardIcon/ArrowForwardIcon';
import { GlobalSpinnerContext } from '../../context/GlobalSpinnerContext';
import { withRouter } from 'react-router-dom';

function UserActivation() {
    const history = useHistory();
    const dispatch = useDispatch();

    const { register, handleSubmit, setError, errors } = useForm({
      validationSchema: loginValidationSchema,
    });

    const { register: registerPasswordConfirmation, handleSubmit: handleSubmitPasswordConfirmation, setError: setErrorPasswordConfirmation, errors: errorsPasswordConfirmation } = useForm({
      validationSchema: passwordConfirmSchema,
    });

    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    const [authData, setAuthData] = useState({
      email: email,
      password: '',
    });

    const [authDataPassword, setAuthDataPassword] = useState({
      password: '',
      passwordConfirmation:''
    });
    
    const [userCredentialsAccepted,setUserCredentialsAccepted] = useState(false);

    const { setGlobalSpinner } = useContext(GlobalSpinnerContext);
    const callSignIn = async () => {
      try {
        setGlobalSpinner(true);
        const user = await dispatch(activeUser(authData));
        if (!user.deleted_at) {
        //   history.push('/');
        setUserCredentialsAccepted(true);
        } else
          setError([
            {
              name: 'email',
              message: 'Your account is frozen, please contact your manager for more details.',
            },
          ]);
      } catch (error) {
        if (error.response) {
          const { status } = error.response;
          let responseErrors;
          if (status === 400) {
            responseErrors = modifyServerErrors(error);
          } else {
            responseErrors = [
              {
                name: 'email',
                message: 'The email address or password is incorrect. Please retry...',
              },
            ];
          }
          setError(responseErrors);
        }
      } finally {
        setGlobalSpinner(false);
      }
    };

    const callChangePassword = async ()=>{
        try{
            setGlobalSpinner(true);
            await dispatch(changeUserPassword({email: authData.email, password: authDataPassword.password}));
            history.push('/login');
        }catch(error){
            if (error.response) {
                const { status } = error.response;
                let responseErrors;
                if (status === 400) {
                  responseErrors = modifyServerErrors(error);
                }
                setErrorPasswordConfirmation(responseErrors);
              }

        }finally{
            setGlobalSpinner(false);
        }   
    }

    return (
    <>
    {
        userCredentialsAccepted && ( 
        <section className="login-page">
            <div className={styles.login__inputWrapper}>
                <UiInputContainer error={errorsPasswordConfirmation.password} label="Password">
                    <UiInputPassword
                        name="password"
                        type="password"
                        ref={registerPasswordConfirmation}
                        value={authDataPassword.password}
                        onChange={value => {
                            setAuthDataPassword({ ...authDataPassword, password: value });
                        }}
                        passwordRevealer
                    />
                </UiInputContainer>
            </div>

            <div className={styles.login__inputWrapper}>
                <UiInputContainer customInput={UiInputPassword} label="Password Confirm" error={errorsPasswordConfirmation.passwordConfirmation}>
                    <UiInputPassword
                        name="passwordConfirmation"
                        type="password"
                        value={authDataPassword.passwordConfirmation}
                        ref={registerPasswordConfirmation}
                        onChange={value => {
                            setAuthDataPassword({ ...authDataPassword, passwordConfirmation: value });
                        }}
                        passwordRevealer
                    />
                </UiInputContainer>
          </div>            
       
          <div className={styles.login__actions}>
            <BaseBtn
              width="80px"
              onClick={handleSubmitPasswordConfirmation(callChangePassword)}
              label="Go"
              icon={<ArrowForwardIcon />}
            />
          </div>            
        </section> )}
        
        {!userCredentialsAccepted && (
            <section className="login-page">
        <div className={styles.login__inputWrapper}>
          <UiInputContainer error={errors.email} label="Email">
            <UiInput
              name="email"
              type='text'
              value={authData.email ?? ''}
              ref={register}
              onChange={value => {
                setAuthData({ ...authData, email: value });
              }}
            />
          </UiInputContainer>
        </div>
              
        <div className={styles.login__inputWrapper}>
          <UiInputContainer customInput={UiInputPassword} label="Password" error={errors.password}>
            <UiInputPassword
              name="password"
              type="password"
              value={authData.password ?? ''}
              ref={register}
              onChange={value => {
                setAuthData({ ...authData, password: value });
              }}
              passwordRevealer
            />
          </UiInputContainer>
        </div>
  
        <div className={styles.login__actions}>
          <BaseBtn
            width="280px"
            onClick={handleSubmit(callSignIn)}
            label="Active Account & choose password"
            icon={<ArrowForwardIcon />}
          />
        </div>
      </section>
        )}
      </>
    );
}

export default withRouter(UserActivation);