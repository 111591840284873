import axios from 'axios';
import moment from 'moment';
import store from 'src/store';
import { downloadBlobDocument } from '../utils/helpers';
import { setGlobalLoadingText } from '../store/layout/actions';
import BaseApi from './BaseApi';

const { dispatch } = store;
const showDownloadProgressStatus = progressEvent => {
  const status = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
  dispatch(setGlobalLoadingText(`${status}%`));
};

class AppendsModel extends BaseApi {
  orderReportName = `Orders Report ${moment().format('HH_mm MM-DD-YYYY')}`;

  constructor() {
    super('/appends');
  }

  getRoles() {
    return axios.get(`${this.resource}/roles`);
  }

  getStationsStatistics(params) {
    return axios.get(`${this.resource}/stations-load`, { params });
  }

  getGeneralOrdersStatistics(params) {
    return axios.get(`${this.resource}/load-gauge`, { params });
  }

  async getOrdersXlsx(data) {
    const response = await axios.get(
      `${this.resource}/orders-xlsx?orders=${JSON.stringify(data)}`,
      {
        responseType: 'blob',
        onDownloadProgress: showDownloadProgressStatus,
      },
    );

    const blobType = 'application/xlsx';
    const fileName = `${this.orderReportName}.xlsx`;
    downloadBlobDocument(response, blobType, fileName);
    return response;
  }

  async getOrdersPdf(data) {
    const response = await axios.get(`${this.resource}/orders-pdf?orders=${JSON.stringify(data)}`, {
      responseType: 'blob',
      onDownloadProgress: showDownloadProgressStatus,
    });

    const blobType = 'application/pdf';
    const fileName = `${this.orderReportName}.pdf`;
    downloadBlobDocument(response, blobType, fileName);
    return response;
  }
}

export default new AppendsModel();
