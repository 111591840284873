import { getArrayWithUpdatedObject } from '../../utils/helpers';

export const initialBaseTableState = {
  loading: false,
  baseModal: {
    isModalVisible: false,
    modalData: null,
    validationErrors: [],
  },
  isClicked: false,
  total: null,
  perPage: null,
  page: null,
  lastPage: null,
  countTypes: null,
  data: []
};

const handleOrders = (state) => {
  if (!state.orders) return { ...state.orders };
  return {
    ...state.orders,
    total: state.orders.total + 1
  }
}
export function getBaseTableState(state = initialBaseTableState, { type, payload }) {
  const add = () => {
    const newData = [...state.data];
    newData.pop();
    const data = [payload, ...newData];
    return {
      ...state,
      data,
      orders: handleOrders(state),
      total: "added",
      baseModal: {
        validationOrderErrors: [],
        isModalVisible: false,
      },
    };
  };

  const edit = () => ({
    ...state,
    baseModal: {
      ...state.baseModal,
      modalData: null,
      validationErrors: [],
      isModalVisible: false,
    },
    data: getArrayWithUpdatedObject(payload, state.data),
  });

  const loading = () => ({ ...state, loading: payload });

  const validation = () => ({
    ...state,
    baseModal: { ...state.baseModal, validationErrors: payload },
  });

  const modalVisibility = () => ({
    ...state,
    baseModal: {
      ...state.baseModal,
      modalData: null,
      validationErrors: [],
      isModalVisible: payload,
    },
  });

  const modalData = () => ({
    ...state,
    baseModal: { ...state.baseModal, isModalVisible: !!payload, modalData: payload },
  });

  const remove = () => ({
    ...state,
    data: state.data.filter(item => item.id !== payload),
  });

  const handlers = new Map([
    [/.*ADD.*/, add],
    [/.*EDIT.*/, edit],
    [/.*MODAL_VISIBILITY.*/, modalVisibility],
    [/.*LOADING.*/, loading],
    [/.*SET_VALIDATION.*/, validation],
    [/.*MODAL_DATA.*/, modalData],
    [/.*REMOVE.*/, remove],
  ]);
  const currentHandler = [...handlers].find(([key]) => key.test(type));
  return currentHandler ? currentHandler[1]() : state;
}
