import { SET_GLOBAL_DATES,  } from 'src/store/actionTypes';
  
const rootReducer = (state = {}, { type, payload }) => {
switch (type) {
    case SET_GLOBAL_DATES:
    const { from, to } = payload    
    return { ...state, from, to };

    default:
    return state;
}
};

export default rootReducer;
  