import React, { useState, createContext } from 'react';
import store from 'src/store';
import { setGlobalLoading } from '../store/layout/actions';

export const GlobalSpinnerContext = createContext();

const GlobalSpinnerContextProvider = ({ children }) => {
  const [loadingText, setLoadingText] = useState();
  return (
    <GlobalSpinnerContext.Provider
      value={{
        setGlobalSpinner: value => store.dispatch(setGlobalLoading(value)),
        loadingText,
        setLoadingText,
      }}
    >
      {children}
    </GlobalSpinnerContext.Provider>
  );
};

export default GlobalSpinnerContextProvider;
