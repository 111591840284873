import React from 'react';
import classNames from 'classnames';
import NacalaLogo from '../../logos/NacalaLogo/NacalaLogo';
import styles from './UserActivationLayout.module.scss';
import manOnTrainImg from '../../../assets/images/auth/man-on-train.jpg';
import triLogicalLogoImg from "../../../assets/images/logo.svg";

const UserActivationLayout = ({ children }) => {
  return (
    <div className={styles.auth}>
    <img src={manOnTrainImg} className={styles.auth__bg} alt="Man on train" />
    <div className={styles.auth__body}>
      <div className={classNames(styles.auth__logoWrapper, styles.auth__nacalaLogo)}>
        <NacalaLogo />
      </div>
      <div className={styles.auth__logoWrapper}>
        <img src={triLogicalLogoImg} alt="TriLogical Railways" />
      </div>
      <div>
        <h3>Welcome To User Activation</h3>
      </div>
      {children}
    </div>
  </div>
  );
};

export default UserActivationLayout;
