import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './UiInputContainer.scss';
import { getErrorMessage } from 'src/utils/validation/validationHelpers';
import { capitalizeFirstLetter } from 'src/utils/helpers';
import { i } from 'react-select/dist/utils-711604ed.esm';

const UiInputContainer = props => {
  const { marginRight, width, label, error, autoHeightError, labelComponent: LabelComponent, children , disabled, isMandatory } = props;

  const errorMsg = error && capitalizeFirstLetter(getErrorMessage(error));

  const renderChildren = () =>
    React.cloneElement(children, {
      error,
    });


  const Label = ()=>{
    return isMandatory ? <div className="ui-input-container__label">{LabelComponent || label} <span style={{color:"red",marginLeft:'3px'}} >*</span> </div> : <div className="ui-input-container__label">{LabelComponent || label}</div>;
  }  

  return (
    <div className="ui-input-container" style={{ pointerEvents: disabled && 'none', width, marginRight }}>
      <div>
        {/* <div className="ui-input-container__label">{LabelComponent || label} {isMandatory ? <div style={{color:"red"}} > *</div> : ""}</div> */}
        <Label />
        {renderChildren()}
      </div>
      <p
        className={classNames('ui-input-container__error', {
          'ui-input-container__error_auto-height': autoHeightError,
        })}
      >
        {errorMsg}
      </p>
    </div>
  );
};

UiInputContainer.propTypes = {
  marginRight: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  autoHeightError: PropTypes.bool,
  isMandatory: PropTypes.bool,
  labelComponent: PropTypes.node,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default React.memo(UiInputContainer);
