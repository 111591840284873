import React from 'react';

function HelloHandIcon({ className }) {
  return (
    <svg
      className={className}
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23898 26.6903L14.9114 29.9653C16.8254 31.0703 19.2705 30.4151 20.3755 28.5012L27.3005 16.5067C27.9905 15.3116 27.5806 13.7817 26.3855 13.0917C26.2383 13.0067 26.081 12.939 25.9275 12.885L26.0675 12.6425C26.7575 11.4474 26.3475 9.91742 25.1524 9.22742C24.8666 9.06242 24.5595 8.95438 24.2483 8.9133C24.1886 8.11673 23.7541 7.36934 23.0093 6.93934C21.8662 6.27934 20.4159 6.63134 19.6879 7.71218C19.5557 7.60119 19.4185 7.49887 19.2713 7.41387C18.0761 6.72387 16.5462 7.13382 15.8562 8.32894L11.0812 16.5995L9.63407 14.286C9.19687 13.5832 8.35379 13.2235 7.54185 13.3898L4.83418 13.9396L7.35117 24.1801C7.61068 25.2306 8.30367 26.1503 9.23898 26.6903ZM9.29324 23.6963L7.26833 15.4835L7.93501 15.3488L11.1532 20.4747L17.5882 9.32894C17.7232 9.09511 18.0288 9.00592 18.2713 9.14592C18.5137 9.28592 18.5943 9.58645 18.4543 9.82894L14.8493 16.073L16.5813 17.073L21.3263 8.8544C21.4663 8.61191 21.7668 8.53139 22.0093 8.67139C22.2518 8.81139 22.3323 9.11191 22.1923 9.3544L17.4473 17.573L19.1794 18.573L23.4694 11.1425C23.6094 10.9 23.9099 10.8195 24.1524 10.9595C24.3949 11.0995 24.4754 11.4 24.3354 11.6425L20.0454 19.073L21.7775 20.073L24.7025 15.0067C24.8425 14.7642 25.143 14.6837 25.3855 14.8237C25.628 14.9637 25.7085 15.2642 25.5685 15.5067L18.6435 27.5012C18.0935 28.4538 16.8641 28.7832 15.9114 28.2332L10.2303 24.9532C9.76266 24.6832 9.42733 24.224 9.29324 23.6963Z"
        fill="#848B9E"
      />
    </svg>
  );
}

export default HelloHandIcon;
