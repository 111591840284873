import React from 'react';

function ArrowForwardIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00016 0.333313L5.82516 1.50831L10.4752 6.16665H0.333496V7.83331H10.4752L5.82516 12.4916L7.00016 13.6666L13.6668 6.99998L7.00016 0.333313Z"
        fill="white"
      />
    </svg>
  );
}

export default ArrowForwardIcon;
