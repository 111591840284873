import React, { useContext, useEffect, useState } from 'react';
import { GlobalSpinnerContext } from '../context/GlobalSpinnerContext';

const asyncComponent = importedComponent => {
  return props => {
    const [C, setComponent] = useState(null);
    const { setGlobalSpinner } = useContext(GlobalSpinnerContext);

    useEffect(() => {
      setGlobalSpinner(true);
      importedComponent().then(cmp => {
        setComponent(() => cmp.default);
        setGlobalSpinner(false);
      });
    }, [setGlobalSpinner]);

    return C ? <C {...props} /> : null;
  };
};

export default asyncComponent;
