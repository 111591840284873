import React from 'react';
import classNames from 'classnames';
import styles from './UiTableHeaderButton.module.scss';

function UiTableHeaderButton({ isButton, onClick, label }) {
  return (
    <th valign="middle">
      <div className={classNames({ [styles.headerButton]: isButton })} onClick={onClick}>
        <span className={styles.label}>{label}</span>
      </div>
    </th>
  );
}

export default UiTableHeaderButton;
