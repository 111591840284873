import React from 'react';
import { NavLink } from 'react-router-dom';
import vector from '../../../assets/images/vector.svg';

function MenuSingleLink({ currentPath, linkPath, IconComponent }) {
  return (
    <NavLink to={linkPath}>
      {currentPath === linkPath && <img src={vector} alt="#" />}
      <IconComponent fill={currentPath === linkPath ? '#005173' : '#A1A6B4'} />
    </NavLink>
  );
}

export default MenuSingleLink;
