import React, { useContext } from 'react';
import styles from './header.module.scss';
import NacalaLogo from '../../logos/NacalaLogo/NacalaLogo';
import HelloHandIcon from '../icons/HelloHandIcon/HelloHandIcon';
import BurgerMenuIcon from '../icons/BurgerMenuIcon/BurgerMenuIcon';
import { UserContext } from '../../../context/UserContext';

const Header = ({ onBurgerClick }) => {
  const { user } = useContext(UserContext);
  const userFullName = user && ` ${user.first_name} ${user.last_name}`;
  return (
    <header className={styles.header}>
      <div className={styles.header__icon}>
        <BurgerMenuIcon onClick={onBurgerClick} />
      </div>
      <div className={styles.headerBlock}>
        <div className={styles.imgWrap}>
          <NacalaLogo />
        </div>
      </div>
      <div className={styles.headerBlock}>
        <HelloHandIcon className={styles.helloIcon} />
        <span className={styles.userName}>{`Hello ${userFullName} !`}</span>
      </div>
    </header>
  );
};

export default Header;
