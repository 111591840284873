import { store } from 'react-notifications-component';
import { capitalizeFirstLetter } from './helpers';

export const NOTIFICATION_TYPES = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
};

export const addNotification = ({
  title,
  message,
  duration = 4000,
  type = NOTIFICATION_TYPES.SUCCESS,
}) =>
  store.addNotification({
    title: capitalizeFirstLetter(title || type),
    message,
    type,
    className: 'ui-notification',
    insert: 'top',
    container: 'top-right',
    duration,
    showIcon: true,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
    },
  });
