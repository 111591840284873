import {
  EDIT_LOCOMOTIVE,
  RESET_LOCOMOTIVES_STATE,
  SET_LOCOMOTIVE_MODAL_DATA,
  SET_LOCOMOTIVE_MODAL_VISIBILITY,
  SET_LOCOMOTIVES,
  SET_LOCOMOTIVES_LOADING,
  SET_VALIDATION_LOCOMOTIVE_ERRORS,
} from 'src/store/actionTypes';

import { initialBaseTableState, getBaseTableState } from '../helpers/baseTableState';

const initialState = {
  ...initialBaseTableState,
};

const locomotivesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_LOCOMOTIVES_STATE:
      return { ...initialState };
    case SET_LOCOMOTIVES:
      return { ...state, ...payload };
    case EDIT_LOCOMOTIVE:
    case SET_LOCOMOTIVE_MODAL_DATA:
    case SET_LOCOMOTIVE_MODAL_VISIBILITY:
    case SET_LOCOMOTIVES_LOADING:
    case SET_VALIDATION_LOCOMOTIVE_ERRORS:
      return getBaseTableState(state, { type, payload });
    default:
      return state;
  }
};

export default locomotivesReducer;
