import React from 'react';
import PropTypes from 'prop-types';
import './BaseButton.scss';
import classNames from 'classnames';

const BaseBtn = ({ onClick, width, disabled, icon, label, marginTop }) => {
  const handleClick = e => {
    if (!disabled) onClick(e);
  };

  return (
    <button
      onClick={handleClick}
      className={classNames('ui-btn', { 'ui-btn__disabled': disabled })}
      style={{ width, marginTop }}
    >
      {icon && <div className={classNames('d-flex', 'ui-btn__icon')}>{icon}</div>}
      <span>{label}</span>
    </button>
  );
};

BaseBtn.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  width: PropTypes.string,
  marginTop: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

BaseBtn.defaultTypes = {
  disabled: false,
};

export default BaseBtn;
