import { SET_GLOBAL_LOADING, SET_GLOBAL_LOADING_TEXT } from 'src/store/actionTypes';

const initialState = {
  isGlobalLoading: false,
  globalLoadingText: '',
  windowWidth: null,
};

const rootReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        globalLoadingText: initialState.globalLoadingText,
        isGlobalLoading: payload,
      };
    case SET_GLOBAL_LOADING_TEXT:
      return { ...state, globalLoadingText: payload };
    default:
      return state;
  }
};

export default rootReducer;
