import { AUTH_LOGOUT, AUTH_SUCCESS, SET_AUTH_ROLES, SET_USER_LOADING } from 'src/store/actionTypes';

const initialState = {
  userLoading: false,
  user: null,
  roles: {
    customer: null,
    order_manager: null,
    train_manager: null,
    admin: null,
    super_admin: null,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return { ...state, user: null };
    case AUTH_SUCCESS:
      return { ...state, user: action.user };
    case SET_AUTH_ROLES:
      return { ...state, roles: action.roles };
    case SET_USER_LOADING:
      return { ...state, userLoading: action.status };
    default:
      return state;
  }
};

export default authReducer;
