import React from 'react';
import styles from './UiLoading.module.scss';

const loader = (
  <svg width="38" height="38" viewBox="0 0 38 38" stroke="red">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

function UiLoading(props) {
  const { loading, lockedScroll, children, backgroundColor, loadingText } = props;

  function getStyle() {
    if (lockedScroll && loading) {
      return {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1500,
      };
    }

    if (loading) {
      return {
        position: 'relative',
        minHeight: '40px',
        minWidth: '40px',
      };
    }
    return {};
  }

  return (
    <div style={getStyle()}>
      {loading && (
        <div className={styles.loadingSection} style={{ backgroundColor }}>
          {loader}
          <div className={styles.loadingText}>{loadingText}</div>
        </div>
      )}
      {children}
    </div>
  );
}

UiLoading.defaultProps = {
  loading: false,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  text: 'UiLoading...',
};

export default UiLoading;
