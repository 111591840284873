import React from 'react';

function TrainIcon({ fill }) {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 0.5 4 0 8 0C12 0 16 0.5 16 4V13.5C16 15.43 14.43 17 12.5 17L14 18.5V19H12L10 17H6L4 19H2V18.5L3.5 17C1.57 17 0 15.43 0 13.5V4ZM4.5 11C3.67157 11 3 11.6716 3 12.5C3 13.3284 3.67157 14 4.5 14C5.32843 14 6 13.3284 6 12.5C6 11.6716 5.32843 11 4.5 11ZM11.5 11C10.6716 11 10 11.6716 10 12.5C10 13.3284 10.6716 14 11.5 14C12.3284 14 13 13.3284 13 12.5C13 11.6716 12.3284 11 11.5 11ZM8 2.00003C11.51 2.00003 12.96 2.48003 13.57 3.00003H2.43C3.04 2.48003 4.49 2.00003 8 2.00003ZM7 5.00003H2V7.99997H7V5.00003ZM14 13.5C14 14.33 13.33 15 12.5 15H3.5C2.67 15 2 14.33 2 13.5V10H14V13.5ZM9 7.99997H14V5.00003H9V7.99997Z"
        fill={fill}
      />
    </svg>
  );
}

TrainIcon.defaultProps = {
  fill: '#005173',
};

export default TrainIcon;
