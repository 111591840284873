import React from "react";
import PropTypes from "prop-types";

const Person = ({ fill }) => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84524 0.375H17.5119C18.6643 0.375 19.6071 1.31786 19.6071 2.47024V17.1369C19.6071 18.2893 18.6643 19.2321 17.5119 19.2321H13.3214L10.1786 22.375L7.03571 19.2321H2.84524C1.68238 19.2321 0.75 18.2893 0.75 17.1369V2.47024C0.75 1.31786 1.68238 0.375 2.84524 0.375ZM12.4519 17.1369H17.5119V2.47024H2.84519V17.1369H7.90519L8.51281 17.7445L10.1785 19.4102L11.8338 17.755L12.4519 17.1369ZM10.1786 9.80357C11.9071 9.80357 13.3214 8.38928 13.3214 6.66071C13.3214 4.93214 11.9071 3.51786 10.1786 3.51786C8.44999 3.51786 7.0357 4.93214 7.0357 6.66071C7.0357 8.38928 8.44999 9.80357 10.1786 9.80357ZM11.2263 6.66071C11.2263 6.08452 10.7548 5.61309 10.1786 5.61309C9.60244 5.61309 9.13101 6.08452 9.13101 6.66071C9.13101 7.2369 9.60244 7.70833 10.1786 7.70833C10.7548 7.70833 11.2263 7.2369 11.2263 6.66071ZM16.4643 14.6017C16.4643 11.9826 12.3053 10.8512 10.1786 10.8512C8.05196 10.8512 3.89291 11.9826 3.89291 14.6017V16.0893H16.4643V14.6017ZM10.1785 12.9464C8.82707 12.9464 7.26612 13.4598 6.49088 13.994H13.8661C13.0909 13.4598 11.5299 12.9464 10.1785 12.9464Z"
        fill={fill}
      />
    </svg>
  );
};
Person.propTypes = {
  fill: PropTypes.string,
};

Person.defaultProps = {
  fill: '#005173',
};

export default Person;
