import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'This field is required',
    label: 'This field',
  },
});

// eslint-disable-next-line func-names
yup.addMethod(yup.date, 'notCurrentDate', function(msg = 'The date must be later than today') {
  return this.test(
    'notCurrentDate',
    msg,
    value => value && value.getTime() >= new Date().getTime(),
  );
});
