import {
  SET_USERS,
  EDIT_USER,
  ADD_USER,
  SET_USERS_LOADING,
  SET_USER_MODAL_VISIBILITY,
  SET_VALIDATION_USER_ERRORS,
  SET_USER_MODAL_DATA,
  RESET_USERS_STATE,
  REMOVE_USER,
} from 'src/store/actionTypes';

import { initialBaseTableState, getBaseTableState } from '../helpers/baseTableState';

const initialState = {
  ...initialBaseTableState,
};

const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_USERS_STATE:
      return { ...initialState };
    case SET_USERS:
      return { ...state, ...payload };
    case ADD_USER:
    case EDIT_USER:
    case SET_USERS_LOADING:
    case SET_VALIDATION_USER_ERRORS:
    case SET_USER_MODAL_DATA:
    case SET_USER_MODAL_VISIBILITY:
    case REMOVE_USER:
      return getBaseTableState(state, { type, payload });
    default:
      return state;
  }
};

export default usersReducer;
