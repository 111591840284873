/* Layout */
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';
export const SET_GLOBAL_LOADING_TEXT = 'SET_GLOBAL_LOADING_TEXT';

/* Auth */
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const SET_AUTH_ROLES = 'SET_AUTH_ROLES';
export const SET_USER_LOADING = 'SET_USER_LOADING';

/* Stations */
export const STATIONS_SET = 'STATIONS_SET';
export const SET_STATIONS_DOWNLOADING = 'SET_STATIONS_DOWNLOADING';

/* Orders */
export const SET_ORDERS = 'SET_ORDERS';
export const EDIT_ORDER = 'EDIT_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const MARK_ORDER_FOR_EXPORT = 'MARK_ORDER_FOR_EXPORT';
export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';
export const SET_ORDER_MODAL_VISIBILITY = 'SET_ORDER_MODAL_VISIBILITY';
export const SET_VALIDATION_ORDER_ERRORS = 'SET_VALIDATION_ORDER_ERRORS';
export const SET_ORDER_MODAL_DATA = 'SET_ORDER_MODAL_DATA';
export const SET_CLICK = 'SET_CLICK';
export const RESET_ORDERS_STATE = 'RESET_ORDERS_STATE';

/* Trains */
export const SET_TRAINS = 'SET_TRAINS';
export const EDIT_TRAIN = 'EDIT_TRAIN';
export const ADD_TRAIN = 'ADD_TRAIN';
export const SET_TRAINS_LOADING = 'SET_TRAINS_LOADING';
export const SET_TRAIN_MODAL_VISIBILITY = 'SET_TRAIN_MODAL_VISIBILITY';
export const SET_VALIDATION_TRAIN_ERRORS = 'SET_VALIDATION_TRAIN_ERRORS';
export const SET_TRAIN_MODAL_DATA = 'SET_TRAIN_MODAL_DATA';
export const RESET_TRAINS_STATE = 'RESET_ORDERS_STATE';
export const EDIT_TRAIN_ORDER = 'EDIT_TRAIN_ORDER';

/* Users */
export const SET_USERS = 'SET_USERS';
export const EDIT_USER = 'EDIT_USER';
export const ADD_USER = 'ADD_USER';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const SET_USER_MODAL_VISIBILITY = 'SET_USER_MODAL_VISIBILITY';
export const SET_VALIDATION_USER_ERRORS = 'SET_VALIDATION_USER_ERRORS';
export const SET_USER_MODAL_DATA = 'SET_USER_MODAL_DATA';
export const RESET_USERS_STATE = 'RESET_USERS_STATE';
export const REMOVE_USER = 'REMOVE_USER';

/* Wagons */
export const SET_WAGONS = 'SET_WAGONS';
export const EDIT_WAGON = 'EDIT_WAGON';
export const SET_WAGONS_LOADING = 'SET_WAGONS_LOADING';
export const SET_WAGON_MODAL_VISIBILITY = 'SET_WAGON_MODAL_VISIBILITY';
export const SET_VALIDATION_WAGON_ERRORS = 'SET_VALIDATION_WAGON_ERRORS';
export const SET_WAGON_MODAL_DATA = 'SET_WAGON_MODAL_DATA';
export const RESET_WAGONS_STATE = 'RESET_WAGONS_STATE';

/* Locomotives */
export const SET_LOCOMOTIVES = 'SET_LOCOMOTIVES';
export const EDIT_LOCOMOTIVE = 'EDIT_LOCOMOTIVE';
export const SET_LOCOMOTIVES_LOADING = 'SET_LOCOMOTIVES_LOADING';
export const SET_LOCOMOTIVE_MODAL_VISIBILITY = 'SET_LOCOMOTIVE_MODAL_VISIBILITY';
export const SET_VALIDATION_LOCOMOTIVE_ERRORS = 'SET_VALIDATION_LOCOMOTIVE_ERRORS';
export const SET_LOCOMOTIVE_MODAL_DATA = 'SET_LOCOMOTIVE_MODAL_DATA';
export const RESET_LOCOMOTIVES_STATE = 'RESET_LOCOMOTIVES_STATE';

/* Dates */
export const SET_GLOBAL_DATES = 'SET_GLOBAL_DATES';

export const RESET_CLICKED = 'RESET_CLICKED';
