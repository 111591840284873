import asyncComponent from './hoc/asyncComponent';
import store from './store';

const ShipmentTracking = asyncComponent(() =>
  import('./containers/ShipmentTracking/ShipmentTracking'),
);
const OrderManagement = asyncComponent(() =>
  import('./containers/OrderManagement/OrderManagement'),
);
const OrderDispatching = asyncComponent(() =>
  import('./containers/OrderDispatching/OrderDispatching'),
);

const Login = asyncComponent(() => import('./containers/auth/Login/Login'));
const ForgotPassword = asyncComponent(() => import('./containers/auth/ForgotPassword/ForgotPassword'));
const MainManagement = asyncComponent(() => import('./containers/MainManagement/MainManagement'));
const TrainManagement = asyncComponent(() => import('./containers/TrainManagement/TrainManagement'));

export const getRoutes = Roles => {
  const AllRoles = Object.values(Roles);
  const admins = [Roles.admin, Roles.super_admin];
  return [
    {
      path: '/login',
      name: 'Home',
      component: Login,
      isPrivate: false,
      layout: 'auth',
    },
    {
      path: '/forgot-password',
      name: 'Forgot password',
      component: ForgotPassword,
      isPrivate: false,
      layout: 'auth',
    },
    {
      path: '/shipment-tracking',
      name: 'Home',
      component: ShipmentTracking,
      isPrivate: true,
      roles: AllRoles,
    },
    {
      path: '/order-management',
      name: 'Home',
      component: OrderManagement,
      roles: [...admins, Roles.order_manager],
      isPrivate: true,
    },
    {
      path: '/main-management',
      name: 'Main Management',
      component: MainManagement,
      roles: [...admins, Roles.order_manager, Roles.train_manager],
      isPrivate: true,
    },
    {
      path: '/order-dispatching',
      name: 'Order Dispatching',
      component: OrderDispatching,
      isPrivate: true,
      roles: [...admins, Roles.train_manager, Roles.order_manager],
    },
    {
      path: '/train-management',
      name: 'Train Management',
      component: TrainManagement,
      isPrivate: true,
      roles: [...admins, Roles.train_manager],
    },
  ];
};

export const getPrivateRoutes = () => {
  const routes = getRoutes(store.getState().auth.roles);
  return routes.filter(route => route.isPrivate);
};
export const getPublicRoutes = () => {
  const routes = getRoutes(store.getState().auth.roles);
  return routes.filter(route => !route.isPrivate);
};
