import { SET_GLOBAL_LOADING, SET_GLOBAL_LOADING_TEXT } from '../actionTypes';

export const setGlobalLoading = isLoading => ({
  type: SET_GLOBAL_LOADING,
  payload: isLoading,
});

export const setGlobalLoadingText = text => ({
  type: SET_GLOBAL_LOADING_TEXT,
  payload: text,
});
