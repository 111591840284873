import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './auth/reducer';
import stationReducer from './stations/reducer';
import layoutReducer from './layout/reducer';
import ordersReducer from './orders/reducer';
import trainsReducer from './trains/reducer';
import usersReducer from './users/reducer';
import wagonsReducer from './wagons/reducer';
import locomotivesReducer from './locomotives/reducer';
import globalDatesReducer from './dates/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  station: stationReducer,
  layout: layoutReducer,
  orders: ordersReducer,
  trains: trainsReducer,
  users: usersReducer,
  wagons: wagonsReducer,
  locomotives: locomotivesReducer,
  globalDates: globalDatesReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
