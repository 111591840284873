import React, { useEffect, useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { Switch, Redirect, Route, useHistory } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { getPublicRoutes, getPrivateRoutes } from './routes';
import PrivateRoute from './components/Router/PrivateRoute';
import PublicRoute from './components/Router/PublicRoute';
import { authCheckProfile } from './store/auth/actions';
import UserContextProvider from './context/UserContext';
import 'react-notifications-component/dist/theme.css';
import UiLoading from './components/UI/UiLoading/UiLoading';
import MainLayout from './components/layouts/MainLayout/MainLayout';
import AuthLayout from './components/layouts/AuthLayout/AuthLayout';
import UserActivationLayout from './components/layouts/UserActivationLayout/UserActivationLayout';
import asyncComponent from './hoc/asyncComponent';
import UserActivation from './containers/UserActivation/UserActivation';

const NotFound = asyncComponent(() => import('./containers/NotFound/NotFound'));

function App() {

  const [isProfileRequestLoaded, setProfileRequestLoaded] = useState(false);
  const { isGlobalLoading, globalLoadingText } = useSelector(state => state.layout);
  const userRole  = useSelector(state => state.auth?.user?.role_id);
  const [navigateByUserRole,setNavigateByUserRole ] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(()=>{
    if(!userRole || !Number.isInteger(userRole) ){

      if(window.location.pathname.split("/").pop() === ''){
        history.push('/login');
      }

      return;
    }

    switch(userRole){
      case 2: 
      case 4: 
      case 5:
        setNavigateByUserRole("/order-management");
        break;
      case 3:
        setNavigateByUserRole("/order-dispatching");
        break;  
      case 1:
        setNavigateByUserRole("/shipment-tracking");
        break;  
    }

  },[userRole])

  useEffect(() => {
    async function loadUser() {
      try {
        await dispatch(authCheckProfile());
      } finally {
        setProfileRequestLoaded(true);
      }
    }
    loadUser();
  }, [dispatch]);

  const publicRoutes = getPublicRoutes();
  const publicPaths = publicRoutes.map(route => route.path);
  const publicDomRoutes = publicRoutes.map(({ path, ...props }) => (
    <PublicRoute key={path} path={path} {...props} />
  ));

  const privateRoutes = getPrivateRoutes();
  const privatePaths = privateRoutes.map(route => route.path);
  const privateDomRoutes = privateRoutes.map(({ path, ...props }) =>{
    return (
      <PrivateRoute key={path} path={path} {...props} />
    )
  });

  return (
    <div className="App">
      <UserContextProvider>
        <UiLoading loading={isGlobalLoading} loadingText={globalLoadingText} lockedScroll />
        <ReactNotification className="ui-notification" />
        {isProfileRequestLoaded && (
          <>
            <Switch>
              <Redirect exact from="/" to={navigateByUserRole} />
              <Route path={publicPaths}>
                <AuthLayout>{publicDomRoutes}</AuthLayout>
              </Route>
              <Route path='/user-activation'>
                <UserActivationLayout>
                  <UserActivation /> 
                </UserActivationLayout>
              </Route>
              <Route path={privatePaths}>
                <MainLayout>{privateDomRoutes}</MainLayout>
              </Route>

              <Route path="*" component={NotFound} />
            </Switch>
          </>
        )}
      </UserContextProvider>
    </div>
  );
}

export default App;
