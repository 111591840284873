export const OrderStatuses = [
  'Received',
  'Loaded',
  'Assigned',
  'In progress',
  'Arrived',
  'Completed',
];

export const TrainStatuses = [
  'Planned',
  'Departed',
  'Arrived',
  'Cancelled'
];


export const getOrderStatusValue = status => OrderStatuses.findIndex(item => item === status);
export const getTrainStatusValue = status => TrainStatuses.findIndex(item => item === status);

export const editableStatuses = [
  getOrderStatusValue('Received'),
  getOrderStatusValue('Assigned'),
  getOrderStatusValue('Loaded'),
];

export const wagonFreezeStatuses = [
  getOrderStatusValue('In progress'),
  getOrderStatusValue('Assigned'),
  getOrderStatusValue('Loaded'),
  getOrderStatusValue('Arrived'),
];

export const editableTrainStatuses = [
  getTrainStatusValue('Planned'),
];

export const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
};

export const VALIDATION_TYPE = {
  CREATION: 'CREATION',
  EDITING: 'EDITING',
};
