import { STATIONS_SET, SET_STATIONS_DOWNLOADING } from 'src/store/actionTypes';

const initialState = {
  stations: [],
  isStationsDownloading: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATIONS_SET:
      return { ...state, stations: action.stations };
    case SET_STATIONS_DOWNLOADING:
      return { ...state, isStationsDownloading: action.downloadingStatus };
    default:
      return state;
  }
};

export default rootReducer;
