import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PublicRoute = ({ component: Component, roles, currentUser, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (rest.isGeneral) {
        return <Component {...props} />;
      }
      if (currentUser && !currentUser.deleted_at) {
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      }

      // not authorised so return our public component
      return <Component {...props} />;
    }}
  />
);

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps)(PublicRoute);
